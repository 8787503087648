import { Box, Link as ChakraLink, Link, Skeleton, Stack, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  EvrCompare,
  EvrCopy,
  EvrGroupWork,
  EvrLifeBuoy,
  EvrPieChart,
  EvrPlus,
  EvrSettings,
  EvrTrash2,
  EvrUsers,
  Flex,
  Text,
} from '../../primitives'
import Image from '../../primitives/Image/Image'
import { Tooltip } from '../../primitives/Tooltip/Tooltip'
import { DropdownMenu } from './MobileMenu'

export const DesktopMenu = ({ userName, showUpgradeButton }) => {
  const { isOpen, onToggle } = useDisclosure()

  const currentPath = window.location.pathname
  const [isHovered, setIsHovered] = useState<string | false>(false)

  const handleMouseEnter = (element: string) => {
    setIsHovered(element)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  // when this feature is ran from "react-entry.php" with react router as provider,
  // we need to use ReactRouterLink in mobile menu for User
  // however when this component is run as old non routed feature,
  // there wont be Router Provider wrapping it, so it will throw error when using ReactRouterLink
  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  return (
    <Box position="fixed" left="0" top="0" w="210px" h="100vh" bg="navy.100" zIndex={1}>
      <Stack m="12px 0px 20px" align="center" spacing="10">
        <Skeleton
          isLoaded={userName}
          height={'40px'}
          width={'180px'}
          borderRadius="md"
          p="0"
          m="0"
          startColor="navy.80"
          endColor="navy.60"
        >
          <DropdownMenu userName={userName} isOpen={isOpen} onToggle={onToggle} />
        </Skeleton>
        <Image
          src="/images/xodosign-logo-text-only-225.png"
          width="143px"
          padding="5px"
          alt="Xodosign Logo"
        />
      </Stack>

      {showUpgradeButton && (
        <Link
          href="/subscription"
          variant={'primary'}
          display={'flex'}
          justifyContent="center"
          alignItems={'center'}
          bg="#00EB9B !important"
          mb="6"
          mx="3"
          data-testid="sidebar-upgrade-subscription-btn"
          fontWeight={500}
          borderBottom={'2px solid rgba(0, 0, 0, .2)'}
          fontSize="sm"
          height={'38px'}
          lineHeight={'38px'}
          backgroundSize={'300% !important'}
          _hover={{
            backgroundImage:
              'linear-gradient(90deg,#99f7d7,#99f7d7 35%,#00EB9B 65%,#00EB9B) !important',
            backgroundPosition: '50% !important',
          }}
        >
          Upgrade!
        </Link>
      )}

      <Flex color="gray.50" direction="column" gap={0.3} height="100%">
        <Text
          data-page-identifier="dashboard"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w="210px"
          bg={currentPath === '/dashboard' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/dashboard"
              href="/dashboard"
              display="flex"
              alignItems={'center'}
            >
              <EvrPieChart w={'20px'} h={'20px'} mr={'8px'} /> Dashboard
            </ChakraLink>
          ) : (
            <Link href="/dashboard" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrPieChart w={'20px'} h={'20px'} mr={'8px'} /> Dashboard
            </Link>
          )}
        </Text>

        <Text
          data-page-identifier="documents"
          h="46px"
          w="210px"
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="13px"
          onMouseEnter={() => handleMouseEnter('documents')}
          onMouseLeave={handleMouseLeave}
        >
          {' '}
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/documents"
              href="/documents"
              display="flex"
              alignItems={'center'}
            >
              <EvrCopy w={'20px'} h={'20px'} mr={'8px'} /> Documents
            </ChakraLink>
          ) : (
            <Link href="/documents" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrCopy w={'20px'} h={'20px'} mr={'10px'} />
              Documents
            </Link>
          )}
          {isHovered === 'documents' && (
            <Tooltip
              label={'New Document'}
              aria-label={'New Document'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              <Link href="/documents/new" _focus={{ boxShadow: 'none' }}>
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <EvrPlus maxW={'12px'} maxH={'12px'} />
                </Flex>
              </Link>
            </Tooltip>
          )}
        </Text>

        <Text
          data-page-identifier="templates"
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="13px"
          width="210px"
          height="46px"
          onMouseEnter={() => handleMouseEnter('templates')}
          onMouseLeave={handleMouseLeave}
        >
          <Link href="/templates" display="flex" _focus={{ boxShadow: 'none' }}>
            <EvrCompare w={'20px'} h={'20px'} mr={'10px'} />
            Templates
          </Link>
          {isHovered === 'templates' && (
            <Tooltip
              label={'New Template'}
              aria-label={'New Template'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              <Link href="/templates/new" _focus={{ boxShadow: 'none' }}>
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <EvrPlus w={'12px'} h={'12px'} />
                </Flex>
              </Link>
            </Tooltip>
          )}
        </Text>

        <Text
          data-page-identifier="contacts"
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="13px"
          width="210px"
          height="46px"
          onMouseEnter={() => handleMouseEnter('contacts')}
          onMouseLeave={handleMouseLeave}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/contacts"
              href="/contacts"
              display="flex"
              alignItems={'center'}
              _focus={{ boxShadow: 'none' }}
            >
              <EvrGroupWork w={'20px'} h={'20px'} mr={'10px'} /> Contacts
            </ChakraLink>
          ) : (
            <Link href="/contacts" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrGroupWork w={'20px'} h={'20px'} mr={'10px'} />
              Contacts
            </Link>
          )}

          {isHovered === 'contacts' && (
            <Tooltip
              label={'New Contact'}
              aria-label={'New Contact'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              {reactRouterEntryNode ? (
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/contacts/new"
                    href="/contacts/new"
                    _focus={{ boxShadow: 'none' }}
                  >
                    <EvrPlus w={'12px'} h={'12px'} />
                  </ChakraLink>{' '}
                </Flex>
              ) : (
                <Link href="/contacts/new" _focus={{ boxShadow: 'none' }}>
                  <Flex
                    as="span"
                    alignItems="center"
                    justifyContent="center"
                    bg="navy.90"
                    width="46px"
                    height="46px"
                    _hover={{ bg: 'navy.80' }}
                  >
                    <EvrPlus w={'12px'} h={'12px'} />
                  </Flex>
                </Link>
              )}
            </Tooltip>
          )}
        </Text>

        <Text
          data-page-identifier="team"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w="210px"
          bg={currentPath === '/team' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/team"
              href="/team"
              display="flex"
              alignItems={'center'}
              data-page-identifier="team"
            >
              <EvrUsers w={'20px'} h={'20px'} mr={'10px'} /> Team
            </ChakraLink>
          ) : (
            <Link href="/team" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrUsers w={'20px'} h={'20px'} mr={'10px'} /> Team
            </Link>
          )}
        </Text>

        <Text
          data-page-identifier="trash"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w="210px"
          bg={currentPath === '/trash' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/trash"
              href="/trash"
              display="flex"
              alignItems={'center'}
            >
              <EvrTrash2 w={'20px'} h={'20px'} mr={'10px'} /> Trash
            </ChakraLink>
          ) : (
            <Link href="/trash" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrTrash2 w={'20px'} h={'20px'} mr={'10px'} /> Trash
            </Link>
          )}
        </Text>

        <Text
          data-page-identifier="settings"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w="210px"
          bg={currentPath === '/settings' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/settings"
              href="/settings"
              display="flex"
              alignItems={'center'}
            >
              <EvrSettings w={'20px'} h={'20px'} mr={'10px'} /> Business Settings
            </ChakraLink>
          ) : (
            <Link href="/settings" display="flex" _focus={{ boxShadow: 'none' }}>
              <EvrSettings w={'20px'} h={'20px'} mr={'10px'} /> Business Settings
            </Link>
          )}
        </Text>

        <Text
          fontSize="sm"
          position="fixed"
          bottom={1}
          display="flex"
          h="46px"
          w="210px"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href="https://help.eversign.com/"
            target="_blank"
            display="flex"
            _focus={{ boxShadow: 'none' }}
          >
            <EvrLifeBuoy w={'20px'} h={'20px'} mr={'10px'} /> Help Center
          </Link>
        </Text>
      </Flex>
    </Box>
  )
}

export default DesktopMenu
