import { Outlet, useLocation } from "react-router-dom";
import { CenterSpinner } from "../CenterSpinner/CenterSpinner";
import { useEffect, useRef, useState } from "react";

export function SpinnerWrapper() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const firstRenderPaths = useRef(new Set<string>()); 

  useEffect(() => {
    // If this path is being rendered for the first time, skip the spinner
    if (!firstRenderPaths.current.has(location.pathname)) {
      firstRenderPaths.current.add(location.pathname); // Mark the path as rendered
      return;
    }

    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300); 

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  if (loading) {
    return <CenterSpinner />;
  }

  return <Outlet />;
}


